import SingleCard from "@components/Card/SingleCard"
import { CARD_NUMBER } from "@constants/SingleCard"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect } from "react"

export type ActionRevealPros = {
  translateX: number
  translateY: number
  rotate: number
  targets: string
  control?: boolean
  delay?: number
  className?: string
  playerPositionStart?: number
  cardType?: string | undefined
  cardNumber?: string | undefined
  animationRef: any
  subAnimations?: any
}

const ActionReveal = (props: ActionRevealPros) => {
  const {
    control = false,
    translateX,
    translateY,
    rotate,
    targets,
    delay = 0,
    className = "",
    playerPositionStart,
    cardType,
    cardNumber,
    animationRef,
    subAnimations,
  } = props

  useEffect(() => {
    // eslint-disable-next-line no-const-assign
    animationRef.current = anime({
      targets: `.${targets}`,
      keyframes: [
        { opacity: 1, duration: 10 },
        {
          translateX: translateX,
          translateY: translateY,
          rotate: rotate,
          easing: "easeInOutQuad",
          autoplay: true,
          scale: 2,
        },
      ],
      delay: delay,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotate, targets, translateX, translateY])

  useEffect(() => {
    console.log('===> (ActionReveal) playerPositionStart: ', subAnimations)
    CARD_NUMBER.forEach((num) => {
      let cardTarget = `.deal-card-${playerPositionStart}-${num}`
      if (document.querySelector(cardTarget)) {
        const cardElement = document.querySelector(
          cardTarget,
        ) as HTMLElement | null
        if (cardElement) {
          cardElement.style.opacity = "0"
        }
      }
      // subAnimations[`deal-card-${playerPositionStart}-${num}`] = {}
      // subAnimations[`deal-card-${playerPositionStart}-${num}`].current = anime({
      //   targets: `.deal-card-${playerPositionStart}-${num}`,
      //   easing: "easeInOutQuad",
      //   autoplay: true,
      //   opacity: 0,
      //   duration: 100,
      // })
    })
  }, [delay, rotate, playerPositionStart])

  return (
    <React.Fragment>
      <SingleCard
        className={className}
        cardType={cardType}
        cardName={cardNumber}
      />
    </React.Fragment>
  )
}

export default ActionReveal

import {
  CHIP_TYPE,
  CHIP_VALUE,
  MAIN_POT_POSITION_TRANSLATE,
} from "@constants/Chip"
import { PLAYER_NUMBER } from "@constants/Player"
import {
  calculateBets,
  clearClassNames,
  randomIntFromInterval,
} from "@helpers/commonFunctions"
import React, { useCallback } from "react"
import ActionCollectChip from "./ActionCollectChip"

export type ActionPlaceRakePros = {
  control?: boolean
  animationRef?: any
  actionType?: string
  typeAction?: string
  type?: string // for skip-mode's type check
  className?: string
  delay?: number
  triggerSkip?: boolean
  forceMuted?: boolean
}

const ActionPlaceRake = (props: ActionPlaceRakePros) => {
  const { animationRef, actionType, typeAction, triggerSkip, forceMuted } =
    props

  const ActionCollectChips = useCallback(() => {
    const Elements: JSX.Element[] = []
    let _counter = 0
    animationRef.is_array = true
    animationRef.subAnimations = []

    const domElements: any =
      document.getElementsByClassName("main-pot-collected")
    const betsMainPot = calculateBets("main-pot-collected", [
      "rake-collected",
      "promo2-collected",
    ])
    let betsForRake = Math.floor((betsMainPot.bet * 10) / 100)
    let playSoundOnlyOne = true
    CHIP_TYPE.forEach((type) => {
      PLAYER_NUMBER.forEach((num) => {
        for (let i = 0; i < domElements.length; i++) {
          const element = domElements[i]
          if (
            !element.classList.contains("rake-collected") &&
            !element.classList.contains("promo2-collected") &&
            element.classList.contains(`${type}-${num}`) &&
            betsForRake > 0
          ) {
            _counter += 1
            const _key = `${type}-${num}-${_counter}-${randomIntFromInterval(
              0,
              99999,
            )}`
            animationRef.subAnimations[_key] = {}
            if (_counter > 1) {
              playSoundOnlyOne = false
            }
            console.log('pushing rake with playSoundOnlyOne', playSoundOnlyOne)
            Elements.push(
              <ActionCollectChip
                translateX={MAIN_POT_POSITION_TRANSLATE[num].X + 118}
                translateY={MAIN_POT_POSITION_TRANSLATE[num].Y + 42}
                targets={element}
                key={_key}
                animationRef={animationRef.subAnimations[_key]}
                actionType={actionType}
                typeAction={typeAction}
                shouldPlaySound={playSoundOnlyOne}
                triggerSkip={triggerSkip}
                forceMuted={forceMuted}
              />,
            )
            betsForRake = betsForRake - CHIP_VALUE[type]
            setTimeout(() => {
              element.classList.add("rake-collected")
              clearClassNames(element, ["main-pot-collected"])
            }, 100)
          }
        }
      })
    })
    return Elements
  }, [animationRef])
  return <React.Fragment>{ActionCollectChips()}</React.Fragment>
}

export default ActionPlaceRake

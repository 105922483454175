import anime from "animejs"
import React, { useEffect, useState } from "react"
import SoundRake2 from "@assets/sound/rake2.mp3"
import { AudioAutoPlayer } from "../../components/Animations/AudioAutoPlayer"

export type ActionDropRakePros = {
  targets: string
  animationRef?: any
  actionType?: string
  triggerSkip?: boolean
  shouldPlaySound?: boolean
  forceMuted?: boolean
}

const ActionDropChip = (props: ActionDropRakePros) => {
  const {
    animationRef,
    targets,
    actionType,
    triggerSkip,
    shouldPlaySound,
    forceMuted,
  } = props
  const [playSound, setPlaySound] = useState(false)

  useEffect(() => {
    let id: any = undefined
    animationRef.current = anime({
      autoplay: true,
      targets: targets,
      opacity: 0,
      easing: "easeInOutQuad",
      duration: 100,
      changeBegin: () => {
        console.log("handle sound for rake2 action")
        if (animationRef.current.direction === "normal") {
          if (triggerSkip) {
            setPlaySound(false)
          } else if (shouldPlaySound === true) {
            console.log("setPlaySound", true)
            if (!forceMuted) {
              setPlaySound(true)
            }
          }
        }
      },
      complete: function (_e) {
        if (this.completed && this.reversed) {
          console.log("completed reverse")
          console.log("setPlaySound", false)
          setPlaySound(false)
        }
      },
    })

    return () => {
      if (id) clearTimeout(id)
    }
  }, [])

  return <React.Fragment>
    <AudioAutoPlayer src={SoundRake2} enabled={playSound} />
  </React.Fragment>
}

export default ActionDropChip

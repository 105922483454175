import ActionShowOut from "@components/Animations/ActionShowOut"
import { PLAYER_POSITION_COIN } from "@constants/Player"
import { COIN_POSITION_TRANSLATE } from "@constants/ShowCoin"
import { randomIntFromInterval } from "@helpers/commonFunctions"
import React, { useCallback, useMemo } from "react"
import SoundC from "@assets/sound/J_c.mp3"
import SoundLandrake from "@assets/sound/landrake.mp3"
import SoundB from "@assets/sound/J_b.mp3"
import SoundR from "@assets/sound/J_r.mp3"
import SoundJA from "@assets/sound/J_a.mp3"
import SoundP from "@assets/sound/J_p.mp3"

export type ActionBlindPros = {
  translateX: number
  translateY: number
  targets: string
  iconType?: string
  counterAction: number
  className?: string
  control?: boolean
  delay?: number
  playerPositionStart: number
  chipInfos?: {
    quantityOfType: any
    bet: number
    change: number
  }
  animationRef?: any
  typeAction?: string
  type?: string // for skip-mode's type check
  triggerSkip?: boolean
}

const ActionBlind = (props: ActionBlindPros) => {
  const {
    delay = 0,
    playerPositionStart,
    chipInfos,
    animationRef,
    counterAction,
    typeAction,
    triggerSkip,
  } = props

  console.log("=======typeAction=======", typeAction)

  const soundType = useMemo(() => {
    if (typeAction === "c") {
      return SoundC
    } else if (typeAction === "b") {
      return SoundB
    } else if (typeAction === "r") {
      return SoundR
    } else if (typeAction === "a") {
      return SoundJA
    } else if (typeAction === "p") {
      return SoundP
    } else {
      return SoundLandrake
    }
  }, [typeAction]);

  const ActionCollectChips = useCallback(() => {
    if (chipInfos) {
      const Elements: JSX.Element[] = []
      let playSoundOnlyOne = true

      animationRef.is_array = true
      animationRef.subAnimations = []

      Object.keys(chipInfos.quantityOfType).forEach((key, index) => {
        if (chipInfos.quantityOfType[key] > 0) {
          for (let i = 1; i <= chipInfos.quantityOfType[key]; i++) {
            const domElements: any = document.getElementsByClassName(
              `${key}-${playerPositionStart}-${i}-${counterAction}`,
            )

            for (let element of domElements) {
              if (
                element.classList.contains("side-pot-collected") ||
                element.classList.contains("main-pot-collected") ||
                element.classList.contains("rake-collected") ||
                element.classList.contains("promo2-collected")
              ) {
                element.classList.remove(`${key}-${playerPositionStart}-${i}`)
              }
            }

            animationRef.checkKey = `${key}-${playerPositionStart}-${i}`
            animationRef.subAnimations[`${key}-${playerPositionStart}-${i}`] = {}

            // check if element is the first element of domElements
            // shouldPlaySound = i === 1

            Elements.push(
              <ActionShowOut
                translateX={
                  COIN_POSITION_TRANSLATE[playerPositionStart].X +
                  randomIntFromInterval(-10, 10)
                }
                translateY={
                  COIN_POSITION_TRANSLATE[playerPositionStart].Y +
                  randomIntFromInterval(-10, 10)
                }
                targets={`${key}-${playerPositionStart}-${i}-${counterAction}`}
                className={`chips-${playerPositionStart}-${key}-${i} chips absolute w-[90px] ${key}-${playerPositionStart} ${key}-${playerPositionStart}-${i}-${counterAction} chips-${playerPositionStart} p-${playerPositionStart}-step-${counterAction} z-[30]`}
                iconType={key}
                delay={delay}
                hideOldIcons={[
                  "icon-f",
                  "icon-c",
                  "icon-a",
                  `chips-${playerPositionStart}`,
                ]}
                key={`${key}-${playerPositionStart}-${i}`}
                animationRef={
                  animationRef.subAnimations[
                    `${key}-${playerPositionStart}-${i}`
                  ]
                }
                style={{
                  top: `${PLAYER_POSITION_COIN[playerPositionStart].top}px`,
                  left: `${PLAYER_POSITION_COIN[playerPositionStart].left}px`,
                }}
                typeAction={typeAction}
                soundType={soundType}
                shouldPlaySound={playSoundOnlyOne}
                triggerSkip={triggerSkip}
              />,
            )
            playSoundOnlyOne = false
          }
        }
      })
      return Elements
    }
  }, [animationRef])

  return <React.Fragment>
    {ActionCollectChips()}
  </React.Fragment>
}

export default ActionBlind

import SingleCard from "@components/Card/SingleCard"
import { MAIN_CARD_POSITION, MAIN_CARD_TRANSLATE } from "@constants/SingleCard"
import { randomIntFromInterval } from "@helpers/commonFunctions"
import React, { useEffect, useState } from "react"
import ActionShowCard from "./ActionShowCard"
import useSound from "use-sound"
import SoundBoard from "@assets/sound/board.mp3"
import anime from "animejs"

export type ActionBoardPros = {
  cards: any[]
  control?: boolean
  delay?: number
  className?: string
  animationRef?: any
}

const ActionBoard = (props: ActionBoardPros) => {
  const {
    control = false,
    delay = 0,
    className = "",
    cards,
    animationRef,
  } = props
  const [elements, setElements] = useState<JSX.Element[]>([])
  const [cardSessions, setCardSessions] = useState<any[]>([])
  // const [playBoard] = useSound(SoundBoard)

  useEffect(() => {
    const CardElements = document.getElementsByClassName("on-main")
    if (cards && cards.length > 0) {
      const newCards = cards.filter((c) => c).slice(CardElements.length)
      const _cardSessions = newCards.map((card, index) => {
        return {
          value: card,
          number: CardElements.length + 1 + index,
        }
      })
      setCardSessions(_cardSessions)
    }
  }, [cards])

  useEffect(() => {
    if (cardSessions && cardSessions.length > 0) {
      const checkExistCard = document.getElementsByClassName("on-main")
      animationRef.subAnimations = []
      animationRef.is_array = true

      animationRef.current = anime({
        changeBegin: () => {
          // playBoard()
        },
      })
      const Elements: JSX.Element[] = []
      const CARD_NUMBER = [1, 2, 3, 4, 5]
      const cardElements = document.getElementsByClassName("board")
      for (let cardElement of cardElements) {
        CARD_NUMBER.forEach((num) => {
          if (
            !cardElement.classList.contains("on-main") &&
            cardElement.classList.contains(`board-${num}`)
          ) {
          }
          animationRef.subAnimations[`board-${num}`] = {}
          Elements.push(
            <ActionShowCard
              targets={`board-${num}`}
              translateX={MAIN_CARD_TRANSLATE.X + 50 * (num - 1)}
              translateY={MAIN_CARD_TRANSLATE.Y}
              scale={MAIN_CARD_TRANSLATE.SCALE}
              key={`board-${num}-${randomIntFromInterval(0, 10000)}`}
              animationRef={animationRef.subAnimations[`board-${num}`]}
              isInit={checkExistCard.length === 0}
            />,
          )
          setTimeout(() => {
            cardElement.classList.add("on-main")
          }, 50)
        })
      }
      setElements(Elements)
    }
  }, [animationRef, cardSessions, delay])

  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [animationRef, control])
  return (
    <React.Fragment>
      {cardSessions.map((cardSession, index) => {
        const [cardName, cardType] = cardSession
          ? cardSession.value.split(";")
          : []
        return cardName && cardType ? (
          <SingleCard
            style={{
              top: `${MAIN_CARD_POSITION.top + 135}px`,
              left: `${MAIN_CARD_POSITION.left + 8}px`,
            }}
            cardType={cardType}
            cardName={cardName}
            className={`opacity-0 absolute w-[26px] ${className} board board-${cardSession.number}`}
            key={cardSession.number + index}
          />
        ) : (
          <React.Fragment key={cardSession.number + index} />
        )
      })}

      {elements.map((Element) => Element)}
    </React.Fragment>
  )
}

export default ActionBoard

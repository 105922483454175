import { CHIP_TYPE, MAIN_POT_POSITION_TRANSLATE, MAIN_POT_V2_POSITION_TRANSLATE } from "@constants/Chip"
import { PLAYER_NUMBER } from "@constants/Player"
import {
  clearChipClassTypePlayerNumber,
  randomIntFromInterval,
} from "@helpers/commonFunctions"
import React, { useCallback, useEffect } from "react"
import ActionCollectChip from "./ActionCollectChip"
import { RootState } from "@redux/store"
import { useSelector } from "react-redux"
import SoundBetAndSide from "@assets/sound/betsandside.mp3"
import useSound from "use-sound"
import anime from "animejs/lib/anime.es.js"


export type ActionCollectBetsPros = {
  control?: boolean
  animationRef?: any
  returnTranslateX?: any
}

const ActionCollectBets = (props: ActionCollectBetsPros) => {
  const { control = false, animationRef, returnTranslateX } = props
  const { user } = useSelector((state: RootState) => state.auth)
  // const [playBetAndSide] = useSound(SoundBetAndSide);

  useEffect(() => {
    animationRef.current = anime.timeline({
      autoplay: true,
    })
    animationRef.current.add({
      easing: "easeInOutQuad",
      changeBegin: () => {
        console.log("playBetAndSide")
        // playBetAndSide()
      },
    })
  }, [
    animationRef,
    // playBetAndSide,
  ])

  useEffect(() => {
    if (!control) {
      // TODO: handle back button
    }
  }, [control])

  const ActionCollectChips = useCallback(() => {
    const Elements: JSX.Element[] = []

    animationRef.is_array = true
    animationRef.subAnimations = []

    CHIP_TYPE.forEach((type) => {
      PLAYER_NUMBER.forEach((num) => {
        const domElements: any = document.getElementsByClassName(
          `${type}-${num}`,
        )

        let _counter = 1
        for (let element of domElements) {
          if (
            !element.classList.contains("rake-collected") &&
            !element.classList.contains("main-pot-collected") &&
            !element.classList.contains("side-pot-collected") &&
            !element.classList.contains("hidden")
          ) {
            element.classList.add(`main-pot-collected-chips-${_counter}`);
            animationRef.subAnimations[`${type}-${num}-${_counter}`] = {}


            const getFirstChipWhite: any = document.getElementsByClassName('chips-1-white-1')
            const getFirstChipBlack: any = document.getElementsByClassName('chips-1-black-1')
            const getFirstChipRed: any = document.getElementsByClassName('chips-1-red-1')
            const getFirstChipGreen: any = document.getElementsByClassName('chips-1-green-1')

            // First chip will be translated to x + 20
            if (getFirstChipWhite?.length && !getFirstChipBlack?.length && !getFirstChipRed?.length && !getFirstChipGreen?.length) {
              for (let el of getFirstChipWhite) {
                el.classList.add('first-chip')
              }
            }
            if (getFirstChipBlack?.length && !getFirstChipWhite?.length && !getFirstChipRed?.length && !getFirstChipGreen?.length) {
              for (let el of getFirstChipBlack) {
                el.classList.add('first-chip')
              }
            }
            if (getFirstChipRed?.length && !getFirstChipBlack?.length && !getFirstChipWhite?.length && !getFirstChipGreen?.length) {
              for (let el of getFirstChipRed) {
                el.classList.add('first-chip')
              }
            }
            if (getFirstChipGreen?.length && !getFirstChipBlack?.length && !getFirstChipWhite?.length && !getFirstChipRed?.length) {
              for (let el of getFirstChipGreen) {
                el.classList.add('first-chip')
              }
            } else if (getFirstChipBlack?.length && (getFirstChipGreen?.length || getFirstChipWhite?.length || getFirstChipRed?.length)) {
              for (let el of getFirstChipBlack) {
                el.classList.add('first-chip')
              }
            } else if (getFirstChipRed?.length && (getFirstChipGreen?.length || getFirstChipWhite?.length || getFirstChipBlack?.length)) {
              for (let el of getFirstChipBlack) {
                el.classList.add('first-chip')
              }
            } else if (getFirstChipWhite?.length && (getFirstChipGreen?.length || getFirstChipRed?.length || getFirstChipBlack?.length)) {
              for (let el of getFirstChipWhite) {
                el.classList.add('first-chip')
              }
            }

            let translateX;
            if (element.classList.contains('first-chip')) {
              translateX = user?.alternatePotLocation ? MAIN_POT_V2_POSITION_TRANSLATE[num].X : MAIN_POT_POSITION_TRANSLATE[num].X + 20
            } else {
              translateX = user?.alternatePotLocation ? MAIN_POT_V2_POSITION_TRANSLATE[num].X + randomIntFromInterval(-10, 10) : MAIN_POT_POSITION_TRANSLATE[num].X + randomIntFromInterval(-20, 20)
            }

            returnTranslateX(translateX)
            Elements.push(
              <ActionCollectChip
                translateX={translateX}
                translateY={
                  (user?.alternatePotLocation ? MAIN_POT_V2_POSITION_TRANSLATE[num].Y : MAIN_POT_POSITION_TRANSLATE[num].Y) +
                  randomIntFromInterval(-2, 2)
                }
                targets={element}
                key={`${type}-${num}-${_counter}`}
                animationRef={
                  animationRef.subAnimations[`${type}-${num}-${_counter}`]
                }
              />,
            )
            _counter += 1
            setTimeout(() => {
              element.classList.add("main-pot-collected")
              element.classList.add("z-20")
              clearChipClassTypePlayerNumber(element, num, type)
            }, 100)
          }
        }
      })
    })
    return Elements
  }, [animationRef])

  return <React.Fragment>{ActionCollectChips()}</React.Fragment>
}

export default ActionCollectBets

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../store"
import axios from "../../config/axiosInstance"
import { setMetric } from "@redux/slices/metricSlice"
import { setStartTime } from "@services/performanceMetricApi"
import fakeDataModePLO from "../../../../hand_file_2_9_data.json"
import fakeDataModeNLHE from "../../../../hand_file_data_nlhe.json"
import { set } from "animejs"

interface ActionState {
  gameMode: string
  actions: object[]
  currentIndex: number
  currentDealerPosition: number
  modalChipIsOpen: boolean
  modalRemainingIsOpen: boolean
  modalCalculateIsOpen: boolean
  modalPromoIsOpen: boolean
  modalHandReaderIsOpen: boolean
  modalActionWindowIsOpen: boolean
  dataModalChip: any
  dataModalRemaining: any
  dataModalCalculate: any
  dataModalPromo: any
  dataModalHandReader: any
  currentExerciseId?: number
  listExerciseIds: number[]
  dataModalChipSubmitted: object[]
  dataModalRemainingSubmitted: object[]
  dataModalPromoSubmitted: object[]
  dataModalCalculateSubmitted: object[]
  dataModalHandReaderSubmitted: object[]
  largestTranslateX: number
  dataHandReader: any[]
  exerciseLoading: boolean
  modalWinActionIsOpen: boolean
  dataModalWinAction: any
  dataModalWinActionSubmitted: object[]
  dataWinPlayersSelected: any[]
  triggerSkipRedux?: boolean
}

const initialState: ActionState = {
  gameMode: "pot_limit_omaha",
  actions: [],
  currentIndex: 0,
  currentDealerPosition: 9,
  modalChipIsOpen: false,
  modalRemainingIsOpen: false,
  modalCalculateIsOpen: false,
  modalPromoIsOpen: false,
  modalActionWindowIsOpen: false,
  currentExerciseId: undefined,
  listExerciseIds: [],
  dataModalChip: {
    actionName: "",
    whileChipNumber: 0,
    redChipNumber: 0,
    greenChipNumber: 0,
    blackChipNumber: 0,
    bet: 0,
    change: 0,
    onBackAction: false,
    modalIndex: 0,
  },
  // structured data to save popup submit
  // { modalIndex: 0, inputBet: undefined, inputChange: undefined },
  dataModalChipSubmitted: [],
  dataModalRemaining: {
    players: 0,
    onBackAction: false,
    modalIndex: 0,
  },
  // structured data to save popup submit
  // { modalIndex: 0, inputPlayers: undefined },
  dataModalRemainingSubmitted: [],
  dataModalCalculate: {
    potNumber: 0,
    rakeNumber: 0,
    sidePotNumber: 0,
    onBackAction: false,
    modalIndex: 0,
    hasRakeNumber: true,
  },
  // structured data to save popup submit
  // { modalIndex: 0, inputPot: undefined, inputSidePot: undefined, inputRake: undefined },
  dataModalCalculateSubmitted: [],
  largestTranslateX: 0,
  dataModalPromo: {
    promo: 0,
    onBackAction: false,
    modalIndex: 0,
  },
  dataModalPromoSubmitted: [],
  dataHandReader: [],
  modalHandReaderIsOpen: false,
  dataModalHandReader: {
    code: "",
    playerPosition: -1,
    onBackAction: false,
    modalIndex: 0,
  },
  dataModalHandReaderSubmitted: [],
  exerciseLoading: true,
  modalWinActionIsOpen: false,
  dataModalWinAction: {
    actionTitle: "",
    winPlayers: [],
    winPlayersSelection: [],
    onBackAction: false,
    modalIndex: 0
  },
  // structured data to save popup submit
  // { modalIndex: 0, winPlayersSelected: [] },
  dataModalWinActionSubmitted: [],
  dataWinPlayersSelected: [],
  triggerSkipRedux: false,
}

const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    nextAction(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload
    },
    backAction(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload
    },
    setActions(state, action: PayloadAction<object[]>) {
      state.actions = action.payload
    },
    resetActions(state) {
      state.actions = []
      state.listExerciseIds = []
      state.dataModalChipSubmitted = []
      state.dataModalRemainingSubmitted = []
      state.dataModalCalculateSubmitted = []
      state.dataModalPromoSubmitted = []
      state.dataModalHandReaderSubmitted = []
      // state.dataHandReader = []
    },
    resetCounter(state) {
      state.currentIndex = 0
      state.dataModalChipSubmitted = []
      state.dataModalRemainingSubmitted = []
      state.dataModalCalculateSubmitted = []
      state.dataModalPromoSubmitted = []
      state.dataModalHandReaderSubmitted = []
      // state.dataHandReader = []
      state.modalChipIsOpen = false
      state.modalRemainingIsOpen = false
      state.modalCalculateIsOpen = false
      state.modalPromoIsOpen = false
      state.modalHandReaderIsOpen = false
    },
    setCurrentDealerPosition(state, action: PayloadAction<number>) {
      state.currentDealerPosition = action.payload
    },
    setModalChipIsOpen(state, action: PayloadAction<boolean>) {
      state.modalChipIsOpen = action.payload
    },
    setDataModalChip(state, action: PayloadAction<any>) {
      state.dataModalChip = { ...state.dataModalChip, ...action.payload }
    },
    setDataModalChipSubmitted(state, action: PayloadAction<any>) {
      state.dataModalChipSubmitted = action.payload
    },
    setModalRemainingIsOpen(stage, action: PayloadAction<boolean>) {
      stage.modalRemainingIsOpen = action.payload
    },
    setDataModalRemaining(state, action: PayloadAction<any>) {
      state.dataModalRemaining = {
        ...state.dataModalRemaining,
        ...action.payload,
      }
    },
    setDataModalRemainingSubmitted(state, action: PayloadAction<any>) {
      state.dataModalRemainingSubmitted = action.payload
    },
    setModalCalculateIsOpen(state, action: PayloadAction<any>) {
      state.modalCalculateIsOpen = action.payload
    },
    setDataModalCalculate(state, action: PayloadAction<any>) {
      state.dataModalCalculate = {
        ...state.dataModalCalculate,
        ...action.payload,
      }
    },
    setDataModalCalculateSubmitted(state, action: PayloadAction<any>) {
      state.dataModalCalculateSubmitted = action.payload
    },
    setCurrentExerciseId(state, action: PayloadAction<any>) {
      state.currentExerciseId = action.payload
    },
    setListExerciseIds(state, action: PayloadAction<any>) {
      state.listExerciseIds = action.payload
    },
    setGameMode(state, action: PayloadAction<string>) {
      state.gameMode = action.payload
    },
    resetActionsState(state) {
      return initialState
    },
    setLargestTranslateX(state, action: PayloadAction<any>) {
      state.largestTranslateX = action.payload
    },
    setDataModalPromo(state, action: PayloadAction<any>) {
      state.dataModalPromo = { ...state.dataModalPromo, ...action.payload }
    },
    setModalPromoIsOpen(state, action: PayloadAction<boolean>) {
      state.modalPromoIsOpen = action.payload
    },
    setDataModalPromoSubmitted(state, action: PayloadAction<any>) {
      state.dataModalPromoSubmitted = action.payload
    },

    setDataModalHandReader(state, action: PayloadAction<any>) {
      state.dataModalHandReader = {
        ...state.dataModalHandReader,
        ...action.payload,
      }
    },
    setModalHandReaderIsOpen(state, action: PayloadAction<boolean>) {
      state.modalHandReaderIsOpen = action.payload
    },
    setModalActionWindowIsOpen(state, action: PayloadAction<boolean>) {
      state.modalActionWindowIsOpen = action.payload
    },
    setDataModalHandReaderSubmitted(state, action: PayloadAction<any>) {
      state.dataModalHandReaderSubmitted = action.payload
    },

    setDataHandReader(state, action: PayloadAction<any>) {
      state.dataHandReader = action.payload
    },
    setExerciseLoading(state, action: PayloadAction<boolean>) {
      state.exerciseLoading = action.payload
    },

    setDataModalWinActionSubmitted(state, action: PayloadAction<any>) {
      state.dataModalWinActionSubmitted = action.payload
    },
    setModalWinActionIsOpen(state, action: PayloadAction<boolean>) {
      state.modalWinActionIsOpen = action.payload
    },
    setDataModalWinAction(state, action: PayloadAction<any>) {
      state.dataModalWinAction = {
        ...state.dataModalWinAction,
        ...action.payload,
      }
    },
    setDataWinPlayersSelected(state, action: PayloadAction<any>) {
      state.dataWinPlayersSelected = action.payload
    },
    selectWinPlayer(state, action: PayloadAction<any>) {
      if (state.dataWinPlayersSelected.indexOf(action.payload) == -1) {
        return {
          ...state,
          dataWinPlayersSelected: [...state.dataWinPlayersSelected, action.payload]
        }
      }
    },
    unSelectWinPlayer(state, action: PayloadAction<any>) {
      return {
        ...state,
        dataWinPlayersSelected: state.dataWinPlayersSelected.filter((item, index) => item !== action.payload)
      }
    },
    setTriggerSkipRedux(state, action: PayloadAction<boolean>) {
      state.triggerSkipRedux = action.payload
    },
  },
})

export const {
  nextAction,
  backAction,
  setActions,
  resetActions,
  resetCounter,
  setCurrentDealerPosition,
  setModalChipIsOpen,
  setDataModalChip,
  setDataModalChipSubmitted,
  setModalRemainingIsOpen,
  setDataModalRemaining,
  setDataModalRemainingSubmitted,
  setModalCalculateIsOpen,
  setDataModalCalculate,
  setDataModalCalculateSubmitted,
  setCurrentExerciseId,
  setListExerciseIds,
  setGameMode,
  resetActionsState,
  setLargestTranslateX,
  setModalPromoIsOpen,
  setDataModalPromo,
  setDataModalPromoSubmitted,
  setDataHandReader,
  setDataModalHandReader,
  setModalHandReaderIsOpen,
  setModalActionWindowIsOpen,
  setDataModalHandReaderSubmitted,
  setExerciseLoading,
  setDataModalWinActionSubmitted,
  setModalWinActionIsOpen,
  setDataModalWinAction,
  selectWinPlayer,
  unSelectWinPlayer,
  setDataWinPlayersSelected,
  setTriggerSkipRedux,
} = actionsSlice.actions

export default actionsSlice.reducer

export const fetchCurrentExerciseId =
  (mode?: string): AppThunk =>
  async (dispatch) => {
    try {
      let uri = `/exercises/current_exercise_for_current_user?mode=${mode}`
      const response = await axios.get(uri)
      if (response.data) {
        dispatch(setCurrentExerciseId(response.data.current_exercise_id))
        dispatch(setCurrentDealerPosition(response.data.dealer_position))
      }
      return true
    } catch (error: any) {
      console.log("error.data", error?.response?.data?.error)
      return false
    }
  }

export const fetchActions =
  (exerciseId?: number, mode = "pot_limit_omaha"): AppThunk =>
  async (dispatch) => {
    try {
      // Call api sign-in
      let uri = `/exercises/list_action?load_exercise=first&mode=${mode}`
      if (exerciseId) {
        uri = `/exercises/list_action?exercise_id=${exerciseId}&mode=${mode}`
      }
      dispatch(setExerciseLoading(true))
      const response = await axios.get(uri)
      // const { steps, exercise_id, information } = fakeDataModePLO
      // const { steps, exercise_id, information } = fakeDataModeNLHE
      const { steps, exercise_id, information } = response.data
      if (exercise_id) {
        dispatch(updateCurrentExerciseIdForUser(exercise_id, mode))
        dispatch(setCurrentExerciseId(exercise_id))
      }
      if (information && information[0]?.exerciseIdsOrdered) {
        dispatch(setListExerciseIds(information[0].exerciseIdsOrdered))
      }
      if (steps) {
        // convent string number to number
        const actions = steps.map((step: any) => {
          const action = { ...step }
          Object.keys(step).forEach((key) => {
            if (!isNaN(action[key])) {
              action[key] = Number(action[key])
            }
          })
          return action
        })
        dispatch(setActions(actions))
      }
      if (exercise_id) {
        console.log(
          `=============== start new metric for exercise_id ${exercise_id} ${mode} ===============`,
        )
        const data = await setStartTime(exercise_id, mode)
        console.log(data)
        dispatch(setMetric(data))
      }
      setTimeout(() => {
        dispatch(setExerciseLoading(false))
      }, 10)
      return true
    } catch (error: any) {
      console.log("error.data", error?.response?.data?.error)
      dispatch(resetActions())
      return false
    }
  }

export const updateCurrentExerciseIdForUser =
  (exerciseId?: any, mode?: string): AppThunk =>
  async (dispatch) => {
    try {
      let response
      if (exerciseId) {
        response = await axios.get(
          `/exercises/update_current_exercise?exercise_id=${exerciseId}&mode=${mode}`,
        )
        if (response.data) {
          dispatch(setCurrentExerciseId(response.data.current_exercise_id))
          dispatch(setCurrentDealerPosition(response.data.dealer_position))
        }
      }
    } catch (error: any) {
      console.log("error.data", error?.response?.data?.error)
    }
  }

export const fetchDataHandReader =
  (mode?: number): AppThunk =>
  async (dispatch) => {
    try {
      let uri = `/hand_readers/data_files?mode=${mode || 0}`
      const response = await axios.get(uri)
      if (response.data) {
        dispatch(setDataHandReader(response.data))
      }
      return true
    } catch (error: any) {
      console.log("error.data", error?.response?.data?.error)
      return false
    }
  }

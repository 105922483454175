import SingleCard from "@components/Card/SingleCard"
import { DURATION_DEFAULT } from "@constants/Actions"
import { SINGLE_CARD_POSITION } from "@constants/SingleCard"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect, useState } from "react"
import { RootState } from "@redux/store"
import { useSelector } from "react-redux"
import SoundBurn from "@assets/sound/burn.mp3"
import { AudioAutoPlayer } from "./AudioAutoPlayer"

export type ActionBurnPros = {
  translateX: number
  translateY: number
  rotate: number
  targets: string
  control?: boolean
  delay?: number
  className?: string
  animationRef?: any
  type?: string
  duration?: number
}

const ActionBurn = (props: ActionBurnPros) => {
  const {
    control = false,
    translateX,
    translateY,
    rotate,
    targets,
    delay = 0,
    className = "",
    animationRef,
    duration,
  } = props
  const { user } = useSelector((state: RootState) => state.auth)
  const [burnId, setBurnId] = useState<number>(1)
  const [playBurn, setPlayBurn] = useState(false)

  useEffect(() => {
    animationRef.current = anime.timeline({
      autoplay: true,
    })

    const translateXMainPot = translateX - 50 - (burnId === 1 ? burnId * 2 : burnId * 2)
    const translateXAlternatePot = translateX - 74 - (burnId === 1 ? burnId * 2 : burnId * 2)

    animationRef.current.add({
      targets: `.${targets}`,
      translateX: [
        {
          value: translateX
        },
        {
          // old
          // value: translateX - 50 - (burnId === 1 ? burnId * 2 : burnId * 2)
          // new main pot position
          value: user?.alternatePotLocation ? translateXAlternatePot : translateXMainPot
          //  By default first card (burnId = 1) in the pot will be translated using "translateX -50".
          // Each cards after the first burn will be move to the right by using burnId * 2
        }
      ],
      left: [
        {
          value: 325 + (burnId === 1 ? burnId * 2 + 2 : burnId * 5 - 1)
          //  By default first card (burnId = 1) in the pot will be moved to from left with "burnId * 2 + 2".
          // Each cards after the first burn will be move to the right by using burnId * 2
        },
      ],
      translateY: [
        {
          value: translateY
        },
        {
          value: translateY - 30
        }
      ],
      rotate: rotate,
      easing: "easeInOutQuad",
      delay: delay,
      duration: duration !== undefined ? duration : DURATION_DEFAULT,
      changeBegin: () => {
        setPlayBurn(true)
      },
      changeComplete: () => {
        setPlayBurn(false)
      }
    })
  }, [burnId, animationRef, delay, targets, translateX, translateY])

  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
        setPlayBurn(true)
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
        setPlayBurn(true)
      }
    } else {
        setPlayBurn(false)
    }

    const getNumberOfBurnCards = document.querySelectorAll('.burn-in-pot')
    if (getNumberOfBurnCards) {
      setBurnId(getNumberOfBurnCards?.length)
    }
  }, [animationRef, control])
  return (
    <React.Fragment>
      <SingleCard
        style={{
          top: `${SINGLE_CARD_POSITION.DEFAULT.TOP + (burnId ? burnId * 2 : 0)}px`,
          left: `${SINGLE_CARD_POSITION.DEFAULT.LEFT}px`,
          zIndex: `${burnId ? (30 - burnId * 2) : 0}`
          // First chip has zIndex =  30
          // The first card will be set under the chip contained class '.first-chip'
          // Each cards will be set under the previous card
        }}
        optionalStyles={false}
        className={`absolute w-[140px] ${className} ${targets} burn-in-pot burn_${burnId}`}
      />
      {/* <AudioAutoPlayer src={SoundBurn} enabled={playBurn} /> */}
    </React.Fragment>
  )
}

export default ActionBurn

import SingleCard from "@components/Card/SingleCard"
import { DURATION_DEFAULT } from "@constants/Actions"
import { SINGLE_CARD_POSITION } from "@constants/SingleCard"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect, useState } from "react"
import SoundDeal from "@assets/sound/deal.mp3"
import { AudioAutoPlayer } from "./AudioAutoPlayer"

export type ActionDPros = {
  translateX: number
  translateY: number
  rotate: number
  targets: string
  control?: boolean
  delay?: number
  className?: string
  animationRef?: any
  type?: string
  duration?: number
  triggerSkip?: boolean | false
  onNextAction?: boolean
  triggerSound?: VoidFunction
}

const ActionD = (props: ActionDPros) => {
  const {
    control = false,
    translateX,
    translateY,
    rotate,
    targets,
    delay = 0,
    className = "",
    animationRef,
    duration,
    triggerSkip,
  } = props

  const [playDealSound, setPlayDealSound] = useState(false)

  useEffect(() => {
    console.log("ActionD useEffecting")
    animationRef.current = anime.timeline({
      autoplay: true,
    })

    animationRef.current = anime({
      targets: `.${targets}`,
      translateX: translateX,
      translateY: translateY,
      rotate: rotate,
      easing: "easeInOutQuad",
      delay: delay,
      duration: duration !== undefined ? duration : DURATION_DEFAULT,
      changeBegin: function (_e) {
        if (animationRef.current.direction === "normal" && !triggerSkip) {
          setPlayDealSound(true)
        }
      },
      complete: function (_e) {
        if (this.completed && this.reversed) {
          console.log("completed reverse")
          console.log("setPlaySound", false)
          setPlayDealSound(false)
        }
      },
      // changeComplete: () => {
      //   console.log("changeComplete actionD", animationRef.onBackAction)
      //   if (!animationRef.onBackAction) {
      //     if (!playDealSound) {
      //       setPlayDealSound(false)
      //     }
      //     if (triggerSkip) {
      //       setPlayDealSound(false)
      //     } else {
      //       setPlayDealSound(true)
      //     }
      //   }
      // },
      // begin: () => {
      //   console.log('begin actionD')
      //   setPlayDealSound(false)
      // },
      // complete: () => {
      //   console.log('complete actionD')
      //   if (!playDealSound) {setPlayDealSound(false)}
      //   if (triggerSkip) {
      //     setPlayDealSound(false)
      //   } else {
      //     setPlayDealSound(true)
      //   }
      // },
    })
  }, [
    animationRef,
    delay,
    duration,
    rotate,
    targets,
    translateX,
    translateY,
    animationRef.skipAction,
    triggerSkip,
  ])

  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [animationRef, control])

  return (
    <React.Fragment>
      <SingleCard
        style={{
          top: `${SINGLE_CARD_POSITION.DEFAULT.TOP}px`,
          left: `${SINGLE_CARD_POSITION.DEFAULT.LEFT}px`,
        }}
        className={`absolute w-[140px] ${className} ${targets}`}
      />
      {/* <AudioAutoPlayer src={SoundDeal} enabled={playDealSound} /> */}
    </React.Fragment>
  )
}

export default ActionD

import { useEffect, useRef } from "react"

/**
 *  How to gain autoplay access.
 *  https://championcr.com/topic/enable-auto-play/#:~:text=Mac%2C%20and%20Firefox.-,Google%20Chrome,)%E2%80%9D%20to%20%E2%80%9CAllow%E2%80%9C
 */

export function AudioAutoPlayer({
  src,
  enabled,
  loop = false,
  replay = false,
}: {
  src: string
  enabled: boolean
  loop?: boolean
  replay?: boolean
  volume?: number
}) {
  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (audioRef.current) {
      if (enabled) {
        audioRef.current.play().catch(e => {
          console.log(e)
        })

        if (replay) {
          audioRef.current.currentTime = 0
          audioRef.current.play().catch(e => {
            console.log(e)
          })
        }
      } else {
        audioRef.current.pause()
      }
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (audioRef.current) audioRef.current.pause()
    }
  }, [enabled, replay])

  return <audio ref={audioRef} src={src} autoPlay={enabled} loop={loop} />
}

import {
  CHIP_TYPE,
  CHIP_VALUE,
  MAIN_POT_POSITION_TRANSLATE,
  MAIN_POT_V2_POSITION_TRANSLATE,
  SIDE_POT_POSITION_TRANSLATE,
} from "@constants/Chip"
import {
  clearChipClassTypePlayerNumber,
  randomIntFromInterval,
} from "@helpers/commonFunctions"
import React, { useCallback, useEffect } from "react"
import ActionCollectChip from "./ActionCollectChip"
import { RootState } from "@redux/store"
import { useSelector } from "react-redux"
import useSound from "use-sound"
import SoundLandrake from "@assets/sound/landrake.mp3"

export type ActionCalculateAndCollectBetsPros = {
  playerPositionEnd: number
  chipToMainPot: number
  chipToSidePot: number
  control?: boolean
  animationRef?: any
}

const ActionCalculateAndCollectBets = (
  props: ActionCalculateAndCollectBetsPros,
) => {
  const {
    playerPositionEnd,
    control = false,
    chipToMainPot,
    animationRef,
  } = props

  const { user } = useSelector((state: RootState) => state.auth)
  // const [playLandrake] = useSound(SoundLandrake)

  // useEffect(() => {
  //   animationRef.current.add({
  //     changeBegin: () => {
  //       console.log("play Sound action Side")
  //       playLandrake()
  //     },
  //   })
  // }, [animationRef, playLandrake])

  useEffect(() => {
    if (!control) {
      // TODO: handle back button
    }
  }, [control])

  const ActionCollectChips = useCallback(() => {
    const Elements: JSX.Element[] = []
    animationRef.is_array = true
    if (!animationRef.subAnimations) {
      animationRef.subAnimations = []
    }
    let _chipToMainPot = chipToMainPot || 0
    CHIP_TYPE.forEach((type) => {
      const domElements: any = document.getElementsByClassName(
        `${type}-${playerPositionEnd}`,
      )
      if (domElements.length > 0) {
        let _counter = 1

        for (let element of domElements) {
          if (
            !element.classList.contains("rake-collected") &&
            !element.classList.contains("main-pot-collected") &&
            !element.classList.contains("side-pot-collected") &&
            !element.classList.contains("promo2-collected") &&
            !element.classList.contains("hidden")
          ) {
            if (!animationRef.subAnimations[`${type}-${_counter}`]) {
              animationRef.subAnimations[`${type}-${_counter}`] = {}
            }
            if (_chipToMainPot > 0) {
              // to main pot
              Elements.push(
                <ActionCollectChip
                  translateX={
                    (user?.alternatePotLocation
                      ? MAIN_POT_V2_POSITION_TRANSLATE[playerPositionEnd].X
                      : MAIN_POT_POSITION_TRANSLATE[playerPositionEnd].X) +
                    randomIntFromInterval(-20, 20)
                  }
                  translateY={
                    (user?.alternatePotLocation
                      ? MAIN_POT_V2_POSITION_TRANSLATE[playerPositionEnd].Y
                      : MAIN_POT_POSITION_TRANSLATE[playerPositionEnd].Y) +
                    randomIntFromInterval(-5, 5)
                  }
                  targets={element}
                  key={`${type}-${playerPositionEnd}-${_counter}`}
                  animationRef={
                    animationRef.subAnimations[`${type}-${_counter}`]
                  }
                />,
              )
              setTimeout(() => {
                element.classList.add("main-pot-collected")
                clearChipClassTypePlayerNumber(element, playerPositionEnd, type)
              }, 100)
              _chipToMainPot = _chipToMainPot - CHIP_VALUE[type]
            } else {
              // To side pot
              Elements.push(
                <ActionCollectChip
                  translateX={
                    SIDE_POT_POSITION_TRANSLATE[playerPositionEnd].X +
                    randomIntFromInterval(-5, 5)
                  }
                  translateY={
                    SIDE_POT_POSITION_TRANSLATE[playerPositionEnd].Y +
                    randomIntFromInterval(-10, 10)
                  }
                  targets={element}
                  key={`${type}-${playerPositionEnd}-${_counter}`}
                  animationRef={
                    animationRef.subAnimations[`${type}-${_counter}`]
                  }
                />,
              )
              setTimeout(() => {
                element.classList.add("side-pot-collected")
                clearChipClassTypePlayerNumber(element, playerPositionEnd, type)
              }, 100)
            }
            _counter += 1
          }
        }
      }
    })
    return Elements
  }, [animationRef, chipToMainPot, playerPositionEnd])
  return <React.Fragment>{ActionCollectChips()}</React.Fragment>
}

export default ActionCalculateAndCollectBets

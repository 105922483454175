import BTN_CLOSE from "@assets/modal/btn-close.png"
import useScalding from "@hooks/useScalding"
import { useAppDispatch } from "@redux/hooks"
import { setModalActionWindowIsOpen } from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import { ActionDto } from "@types"
import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

const ModalActionWindow = ({
  scalding,
  isOpenHandNarrator,
}: {
  scalding: number
  isOpenHandNarrator?: boolean
}) => {
  const { modalActionWindowIsOpen, actions, currentIndex } = useSelector(
    (state: RootState) => state.actions,
  )
  const { scaldingMaintable } = useScalding()
  const dispatch = useAppDispatch()

  const [countKeydownZ, setCountKeydownZ] = React.useState(0)

  const onCloseModal = () => {
    dispatch(setModalActionWindowIsOpen(false))
  }

  const text = useMemo(() => {
    const action = actions[currentIndex - 1] as ActionDto
    if (!action) return ""
    if (action.type === "l") {
      return "Blind/Ante posted."
    }
    if (action.type === "f") {
      return "Player Folds."
    }
    if (action.type === "p") {
      return "Player bets Pot."
    }
    if (action.type === "c") {
      return "Player Calls."
    }
    if (action.type === "r") {
      return `Player Raises to ${action.bet}.`
    }
    if (action.type === "f2") {
      return "Player Folds."
    }
    if (action.type === "k") {
      return "Player Checks."
    }
    if (action.type === "a") {
      return `Player is All In for ${action.bet}.`
    }
    if (action.type === "b") {
      return `Player bets ${action.bet}.`
    }
    if (action.type === "a2") {
      return "This player is already All In."
    }
    return ""
  }, [actions, currentIndex])

  useEffect(() => {
    // Keep default size of action window when zooming
    const handleOnZoom = (event: KeyboardEvent) => {
      const windowProps = window as any
      if (!windowProps) return
      if (event.key.toLocaleLowerCase() === "z") {
        setCountKeydownZ(windowProps.countKeydownZ)
      }

      // if(event.key == "d"){
      //   const nextBtnElement = document.getElementById("nextBtn")
      //   nextBtnElement?.click()
      // }
    }

    window.addEventListener("keydown", handleOnZoom)

    return () => {
      window.removeEventListener("keydown", handleOnZoom)
    }
  }, [isOpenHandNarrator])

  const position = useMemo(() => {
    if (countKeydownZ === 1 || countKeydownZ === 2) {
      return {
        left: "50%",
        top: 420,
      }
    }
    if (isOpenHandNarrator) {
      return {
        left: "calc(50% + 82px)",
        top: 370,
      }
    }
    return {
      left: "50%",
      top: 370,
    }
  }, [countKeydownZ, isOpenHandNarrator])

  return (
    <React.Fragment>
      {modalActionWindowIsOpen && (
        <div
          style={{
            marginRight: "-50%",
            transform: "translate(-50%, 0)",
            padding: "0px",
            width: "fit-content",
            overflow: "hidden",
            position: "absolute",
            zIndex: 101,
            zoom: scaldingMaintable,
            ...position,
          }}
          key={text}
        >
          <div
            style={{
              fontFamily: "Lato",
            }}
            className="relative w-[248px] h-[155px] text-white bg-[#224670]"
          >
            <div className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-fit p-2 text-center text-3xl">
              {text}
            </div>
            <img
              onClick={onCloseModal}
              src={BTN_CLOSE}
              alt="BTN_CLOSE"
              className="w-[20px] absolute top-[6px] right-[8px] cursor-pointer"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default ModalActionWindow

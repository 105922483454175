import { getActionTargets } from "@helpers/commonFunctions"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect, useState } from "react"
import SoundLandrake from "@assets/sound/landrake.mp3"
import { AudioAutoPlayer } from "./AudioAutoPlayer"


export type ActionCollectChipPros = {
  translateX: number
  translateY: number
  targets: any | any[] | undefined
  control?: boolean
  delay?: number
  animationRef?: any
  actionType?: string
  typeAction?: string
  shouldPlaySound?: boolean
  triggerSkip?: boolean
  forceMuted?: boolean
}

const ActionCollectChip = (props: ActionCollectChipPros) => {
  const {
    translateX,
    translateY,
    targets,
    delay = 0,
    animationRef,
    actionType,
    shouldPlaySound,
    typeAction,
    triggerSkip,
    forceMuted,
  } = props

  const [playSound, setPlaySound] = useState(false)

  useEffect(() => {
    animationRef.current = anime({
      targets: getActionTargets(targets),
      translateX: translateX,
      translateY: translateY,
      easing: "easeInOutExpo",
      autoplay: true,
      delay: delay,
      changeBegin: () => {
        console.log(
          "changeBegin in ActionCollectChip running! shouldPlaySound",
          shouldPlaySound,
        )
        console.log("--- typeAction", typeAction)

        if (animationRef.current.direction === "normal") {
          if (triggerSkip) {
            setPlaySound(false)
          } else if (shouldPlaySound === true) {
            if (!forceMuted) {
              setPlaySound(true)
            }
          }
        }
      },
      complete: function (_e) {
        if (this.completed && this.reversed) {
          console.log("completed reverse")
          console.log("setPlaySound", false)
          setPlaySound(false)
        }
      },
    })
  }, [animationRef, delay, targets, translateX, translateY, actionType])
  return <React.Fragment>
    <AudioAutoPlayer src={SoundLandrake} enabled={playSound} />
  </React.Fragment>
}

export default ActionCollectChip

import { getElementForActionShowOut } from "@helpers/commonFunctions"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@redux/store"
import SoundK from "@assets/sound/J_k.mp3"
import SoundF from "@assets/sound/J_f.mp3"
import SoundF2 from "@assets/sound/J_f2.mp3"
import { AudioAutoPlayer } from "./AudioAutoPlayer"

export type ActionShowOutPros = {
  translateX: number
  translateY: number
  targets: string
  iconType: string
  className?: string
  control?: boolean
  delay?: number
  hideOldIcons?: string[]
  animationRef?: any
  style?: React.CSSProperties | undefined
  typeAction?: string
  triggerSkip?: boolean
  onBackAction?: boolean
  soundType?: string
  shouldPlaySound?: boolean
  forceMuted?: boolean
}

const ActionShowOut = (props: ActionShowOutPros) => {
  const {
    control = false,
    translateX,
    translateY,
    targets,
    delay = 0,
    iconType,
    className = "",
    hideOldIcons = [],
    animationRef,
    typeAction,
    style,
    triggerSkip,
    onBackAction,
    soundType,
    shouldPlaySound,
    forceMuted,
  } = props
  // const animationRef = useRef<AnimeInstance | null>(null)
  const [hideOld, setHideOld] = useState(false)
  const [playSound, setPlaySound] = useState(false)
  const [playSoundFold, setPlaySoundFold] = useState(false)
  const [playSoundFold2, setPlaySoundFold2] = useState(false)

  // get gameMode for check chip Coin
  const { gameMode } = useSelector((state: RootState) => state.actions)

  useEffect(() => {
    if (hideOld) {
      animationRef.current = anime({
        targets: `.${targets}`,
        autoplay: true,
        delay: delay,
        keyframes: [
          {
            opacity: 1,
            duration: 5,
          },
          {
            translateX: translateX,
            translateY: translateY,
            easing: "easeInOutExpo",
            duration: 450,
          },
        ],
        changeBegin: function (_e) {
          console.log(
            "changeBegin in ActionShowOut running! shouldPlaySound",
            shouldPlaySound,
          )
          console.log("--- typeAction", typeAction)
          if (typeAction === "k") {
            if (animationRef.current.direction === "normal") {
              if (triggerSkip) {
                setPlaySound(false)
              } else {
                console.log("setPlaySound", true)
                if (!forceMuted) {
                  setPlaySound(true)
                }
              }
            }
          } else if (typeAction === "f") {
            console.log("handle sound for fold action")
            if (animationRef.current.direction === "normal") {
              if (triggerSkip) {
                setPlaySoundFold(false)
              } else if (shouldPlaySound === true) {
                console.log("setPlaySoundFold", true)
                if (!forceMuted) {
                  setPlaySoundFold(true)
                }
              }
            }
          } else if (typeAction === "f2") {
            console.log("handle sound for fold2 action")
            if (animationRef.current.direction === "normal") {
              if (triggerSkip) {
                setPlaySoundFold2(false)
              } else if (shouldPlaySound === true) {
                console.log("setPlaySoundFold2", true)
                if (!forceMuted) {
                  setPlaySoundFold2(true)
                }
              }
            }
          } else if (animationRef.current.direction === "normal") {
            if (triggerSkip) {
              setPlaySound(false)
            } else if (shouldPlaySound === true) {
              console.log("setPlaySound", true)
              if (!forceMuted) {
                setPlaySound(true)
              }
            }
          }
        },
        update: function (_e) {
          this.animatables[0].target.classList.remove("hidden")
        },
        complete: function (_e) {
          if (this.completed && this.reversed) {
            console.log("completed reverse")
            console.log("setPlaySound", false)
            setPlaySound(false)
            setPlaySoundFold(false)
            setPlaySoundFold2(false)
            this.animatables[0].target.classList.add("hidden")
          }
        },
      })
    }
  }, [
    animationRef,
    delay,
    hideOld,
    targets,
    translateX,
    translateY,
    typeAction,
  ])

  useEffect(() => {
    // hide old show out icon
    const skipped = ["pot", "a2", "k"]
    if (
      !skipped.includes(typeAction || "") &&
      !hideOld &&
      hideOldIcons.length > 0
    ) {
      hideOldIcons.forEach((iconClass: string) => {
        const oldElements: any = document.getElementsByClassName(iconClass)
        for (let element of oldElements) {
          if (
            !element.classList.contains("side-pot-collected") &&
            !element.classList.contains("main-pot-collected") &&
            !element.classList.contains("rake-collected") &&
            !element.classList.contains("promo2-collected")
          ) {
            // console.log("-------- add hidden", element)
            element.classList.add("hidden")
          }
        }
      })
    }
    setTimeout(() => {
      setHideOld(true)
    }, 10)
  }, [hideOld, hideOldIcons, iconType, typeAction])

  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        setPlaySound(true)
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
        setPlaySound(true)
      }
    } else {
      setPlaySound(false)
    }
  }, [animationRef, control])

  return (
    <React.Fragment>
      {hideOld && (
        <div style={style} className={`opacity-0 ${className}`}>
          {getElementForActionShowOut(gameMode, iconType)}
        </div>
      )}
      <AudioAutoPlayer src={soundType || SoundK} enabled={playSound} />
      <AudioAutoPlayer src={SoundF} enabled={playSoundFold} />
      <AudioAutoPlayer src={SoundF2} enabled={playSoundFold2} />
    </React.Fragment>
  )
}

export default ActionShowOut
